<template>
  <div>
    <h3>404 Not found</h3>
  </div>
</template>
<script>
export default {
  
}
</script>
<style lang="">
  
</style>